import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {first} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute} from '@angular/router';

enum ErrorStates {
    NotSubmitted,
    HasError,
    NoError
}

@UntilDestroy()
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    errorState: ErrorStates = ErrorStates.NotSubmitted;
    errorStates = ErrorStates;
    isLoading$: Observable<boolean>;
    errorMessage
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    constructor(private fb: FormBuilder, private authService: AuthService, private route: ActivatedRoute) {
        this.isLoading$ = this.authService.isLoading$;
    }
    token: string;

    ngOnInit(): void {
        this.initForm();
        this.route.paramMap.pipe(untilDestroyed(this)).subscribe(async paramMap => {
            this.token = paramMap.get('token');
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.forgotPasswordForm.controls;
    }

    matchPassword(control: AbstractControl) {
        const password = control.get('password').value;

        const confirmPassword = control.get('password2').value;

        if (password !== confirmPassword) {
            control.get('password2').setErrors({confirmPassword: true});
        } else {
            return null;
        }
    }

    initForm() {
        this.forgotPasswordForm = this.fb.group(
            {
                password: [
                    '',
                    Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])
                ],
                password2: [
                    '',
                    Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])
                ]
            },
            {
                validator: this.matchPassword
            }
        );
    }

    submit() {
        if (!this.forgotPasswordForm.valid) {
            this.forgotPasswordForm.markAsTouched();
            return;
        }
        this.errorState = ErrorStates.NotSubmitted;
        const forgotPasswordSubscr = this.authService
            .resetPassword(this.token, this.f.password.value)
            .pipe(first())
            .subscribe(
                (result: boolean) => {
                    this.errorState = result ? ErrorStates.NoError : ErrorStates.HasError;
                },
                e => {
                    this.errorMessage = e;
                    this.errorState = ErrorStates.HasError;
                }
            );
        this.unsubscribe.push(forgotPasswordSubscr);
    }
}
