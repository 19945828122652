import { Component, OnInit } from '@angular/core';
import {User} from "../../../data/user/user.model";
import {AuthService} from "../../auth/_services/auth.service";

@Component({
  selector: 'app-el-nft-home',
  templateUrl: './el-nft-home.component.html',
  styleUrls: ['./el-nft-home.component.scss']
})
export class ElNftHomeComponent implements OnInit {

  user: User;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.user = this.authService.currentUser;

    console.log(this.user);
  }

}
