import {Injectable, NgModule} from '@angular/core';
import {Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthModule} from "./modules/auth/auth.module";


export const routes: Routes = [
    {
        path: 'error',
        loadChildren: () => import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
    },
    {
        path: 'auth',
        loadChildren: () => AuthModule
    },
    {
        path: '**',
        redirectTo: '/error/404',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', enableTracing: false})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
