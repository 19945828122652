import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {UserAction} from "./user-action.model";

@Injectable({providedIn: 'root'})
export class UserActionService extends EntityCollectionServiceBase<UserAction> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('user-actions', serviceElementsFactory);
    }
}
