<div class="d-flex flex-column flex-root h-100" id="kt_login_wrapper" [style.background-image]="'url(' + ui.settingsMap['page.auth.bg'] + ')'">
    <header class="h-100">
        <div class="overlay"></div>

        <iframe id="video"
                src="https://fast.wistia.net/embed/iframe/7shq4616dq?muted=true&amp;autoplay=true&amp;controlsVisibleOnLoad=false&amp;playbar=false&amp;endVideoBehavior=loop&amp;playButton=false&amp;volumeControl=false&amp;fullscreenButton=false&amp;qualityControl=false&amp;playPauseNotifier=false&amp;playbackRateControl=false&amp;playerColor=000000"
                allowfullscreen="" allow="autoplay; fullscreen" frameborder="0" scrolling="no" class="embed-responsive-item2"></iframe>

        <div class="d-flex h-100 auth-form align-items-end justify-content-center" (click)="isFormVisible = true">
            <div class="card" *ngIf="isFormVisible" style="min-width: 450px;">
                <div class="card-body">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </header>
</div>
