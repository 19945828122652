<div [ngClass]="headerContainerCSSClasses" class="d-flex justify-content-between">

  <div *ngIf="ui.cmsMenu" class="header-menu-wrapper" id="kt_header_menu2_wrapper">
    <app-header-menu-dynamic id="kt_header_menu2" 
      class="header-menu header-menu-left header-menu-mobile header--menu-left"
      [menu]="ui.cmsMenu['main']"></app-header-menu-dynamic>
  </div>
  
  <div class="header-logo d-flex justify-content-center">
    <a [routerLink]="ui.homeUrl$|async">
      <ng-container>
        <img [src]="logoPath" alt="" class="logo-default max-h-40px p-2" />
        <img [src]="logoPath" alt="" class="logo-sticky max-h-40px p-2" />
      </ng-container>
    </a>
  </div>
  
  <div>
    <div class="header-menu-wrapper justify-content-end" *ngIf="!!ui.cmsMenu['user']">
      <app-header-menu-dynamic class="header-menu header-menu--right"
        [menu]="ui.cmsMenu['user']"></app-header-menu-dynamic>
    </div>
  </div>
</div>
