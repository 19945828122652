export function isVideo(url: string): boolean {
  if (!url) return false;
  const r = /(.mp4|.avi|.3gpp|.mov|.webm|.mpeg|.ogg)$/;
  return r.test(url.toLowerCase());
}

export function untilEvent(event: string): Promise<any> {

  return new Promise(resolve=>
    window.addEventListener(event, (e: any)=>
      resolve(e?.detail)));

}

export function generateRandomText(): string {

  const randomText = (): string =>
      Math.random().toString(36).slice(2);

  return [randomText(), randomText()].join('');

}

export function query(selector: string, el?: HTMLElement): HTMLElement|null {

  return !el ?
    document.querySelector(selector) :
    el.querySelector(selector);

}

export function listify(selector: string, el?: HTMLElement): HTMLElement[]|null {

  return !el ?
    Array.from(document.querySelectorAll(selector)) :
    Array.from(el.querySelectorAll(selector));

}


export function injectScriptTag(resourceUrl: string, opts?: any): HTMLScriptElement {

  const { isAsync, tagId, idPrefix } = opts || {}

  const el: HTMLScriptElement = document.createElement('SCRIPT') as HTMLScriptElement;

  el.src = resourceUrl;
  el.id = tagId || (idPrefix ? `${idPrefix}-${generateRandomText()}`:generateRandomText());

  if ( isAsync ) el.async = true

  document.head.appendChild(el)

  return el


}

export const wistiaIframeTagRegex = /\<iframe\s+src\=\".*?\.wistia.+\<\/iframe>/mg;
export const wistiaVideoIdRegex = /fast\.wistia\.net\/embed\/iframe\/([\S]+)\?/;

export function supportWistiaPopover(page: string): string {

  try {

    return page.slice().replace(wistiaIframeTagRegex, m=> {

      const [ _, videoId ] = m.match(wistiaVideoIdRegex)||[];

      //return m.replace(m, `${m}<span class="wistia_embed wistia_async_${videoId} popover=true popoverContent=link"><button></button></span>`);
      return `<div class="wistia_embed wistia_async_${videoId} embed-responsive-item"></div>`;
    });

  } catch(e) { return page }

}

export function triggerWindowResize(): boolean {

  try {

    // cross-browser compatible
    const resizeEvent: any = window.document.createEvent('UIEvents') as any
    (resizeEvent as any).initUIEvent('resize', true, false, window, 0)

    return window.dispatchEvent(resizeEvent)

  } catch(e) {

    // for modern browsers
    return window.dispatchEvent(new Event('resize'))
  }

}

export function dataPlaceholderRegex(key: string): RegExp {
  return new RegExp(`\{\{\s*?${key}\s*?\}\}`, 'g');
}

export function whenElement(selector: string): Promise<HTMLElement> {
  return new Promise(resolve => {

    if (query(selector)) return resolve(query(selector) as HTMLElement);

    const observer = new MutationObserver(mutations => {
      if (query(selector)) {
        resolve(query(selector) as HTMLElement);
        observer.disconnect();
      }
    });

    return observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export function trackByRefactored(attr?: string): any {

  if ( !attr ) return function refactoredUtilityFn(i: number) { return i }
  return function refactoredUtilityFn(_: any, item: any) { return !!item && item[attr] }

}

export function normalizeName(v: string = ''): string {

  // https://stackoverflow.com/questions/286921/efficiently-replace-all-accented-characters-in-a-string
  // convert all accented chars to latin chars

  // added to retain the accented characters e.g.
  // first name in French is Prénom
  // before normalize, it's turned into Prnom
  // after normalize, the term is turned to be Prenom

  const unaccented: string = v

    .trim()

    // changed accented to latin chars
    .normalize('NFKD')

    // remove all emoji chars
    .replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
    .replace(/(\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')

    // remove accented chars
    .replace(/[\u0300-\u036F]/g, '')

    // remove all non words, digits, space, hyphens
    .replace(/[^\w\d\s\-]+/g, '')

    // remove all chars matching pattern - lookup comment #1
    .replace(/\s+(\-{1,})\s+/g, '-')

    // remove all consecutive (at least 2) hyphens
    .replace(/\-{2,}/g, '')
    .trim()

    // replace space with hyphen
    .replace(/\s/g, '-')

    // remove all underscores
    .replace(/\_/g, '')

  return unaccented.toLowerCase()

}

export function asArray(a: Set<any>): any[] {

  return Array.from(a);

}

export function rangeActualValue(input: number, min: number, max: number): number {
  return ((input - min) * 100) / (max - min);
}

export function flattendArray(array: any[]): any[] {

  return [].concat.apply([], array)

}