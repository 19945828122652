import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const emptyMenuConfig = {
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class DynamicAsideMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;

  constructor() {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
  }

  public setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
