import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy, HostListener, ChangeDetectionStrategy, ViewEncapsulation,
} from '@angular/core';
import {
    Router,
    NavigationStart,
    RouteConfigLoadStart,
    RouteConfigLoadEnd,
    NavigationEnd,
    NavigationCancel,
} from '@angular/router';
import {LayoutService} from '../../../../_metronic/core';
import KTLayoutHeader from '../../../../../assets/js/layout/base/header';
import KTLayoutHeaderMenu from '../../../../../assets/js/layout/base/header-menu';
import {KTUtil} from '../../../../../assets/js/components/util';
import {Subscription, Observable, BehaviorSubject, takeUntil, Subject} from 'rxjs';
import {UiService} from '../../../../lib/ui/services/ui.service';
import {environment} from '../../../../../environments/environment';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@Component({
    host: {
        //"[class]": "ui.isCmsWebsite ? 'cms-website-header':'admin-page-header'",
    },
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  public headerContainerCSSClasses: string = '';
  public headerMenuSelfDisplay: boolean = false;
  public asideSelfDisplay: boolean = false;
  public headerSelfTheme: string = '';
  public headerMenuCSSClasses: string = '';
  public headerMenuHTMLAttributes: any = {};
  public routerLoaderTimout: any;

  public whichHeaderMenuIsOpen: string = '';

  public isLogoAnimated: boolean = false;
  public logoPath: string = '';
  public logoPathDark: string = '';

  public logoStyles: Partial<CSSStyleDeclaration> = {
    //maxWidth: '100%',
    //height: 'auto',
    // width: '200px',
    // height: '200px',
    height: '40px'
  };

  @ViewChild('ktHeaderMenu', {static: true}) ktHeaderMenu!: ElementRef;

  public loader$: Observable<number>;

  private loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public showExtraDsMenu: boolean = false;

  private destroy$: Subject<void>

  constructor(
    private layout: LayoutService,
    private router: Router,
    public ui: UiService
  ) {

    this.destroy$ = new Subject();

    this.loader$ = this.loaderSubject;

    // page progress bar percentage
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {

        if (e instanceof NavigationStart) this.loaderSubject.next(10);

        if (e instanceof RouteConfigLoadStart) this.loaderSubject.next(65);

        if (e instanceof RouteConfigLoadEnd) this.loaderSubject.next(90);

        if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
          //ui.logAction(`navigated:${event.url}`)

          // set page progress bar loading to end on NavigationEnd event router
          this.loaderSubject.next(100);
          if (this.routerLoaderTimout) {
            clearTimeout(this.routerLoaderTimout);
          }
          this.routerLoaderTimout = setTimeout(() => {
            this.loaderSubject.next(0);
          }, 300);
        }
      });

    this.ui.logo$
      .pipe(takeUntil(this.destroy$))
        .subscribe(logo => this.setLogo(logo));

    this.setLogo(this.ui.getSetting('logo'));

    this.ui.isWebsiteLoaded$
      .pipe(takeUntil(this.destroy$))
        .subscribe((state) => {

          if (!state) return;

          this.headerContainerCSSClasses = 'container-fluid p-0 w-33';
          setTimeout(() => KTLayoutHeaderMenu.init('kt_header_menu2', 'kt_header_menu2_wrapper'), 100);

        });
  }

  ngOnInit(): void {

    this.headerContainerCSSClasses = this.layout.getStringCSSClasses('header_container');

    this.headerMenuSelfDisplay = this.layout.getProp('header.menu.self.display');
    this.asideSelfDisplay = this.layout.getProp('aside.self.display');
    this.headerSelfTheme = this.layout.getProp('header.self.theme') || '';
    this.headerMenuCSSClasses = this.layout.getStringCSSClasses('header_menu');
    this.headerMenuHTMLAttributes = this.layout.getHTMLAttributes('header_menu');

    this.logoPath = 'https://eccoleather.blob.core.windows.net/static/logo/ecco-leather-logo-black.png';

    this.ui.headerContainerCSSClasses$
      .pipe(takeUntil(this.destroy$))
      .subscribe(css => {
        this.headerContainerCSSClasses = css;
      });
  }

  ngAfterViewInit(): void {

    if (this.ktHeaderMenu) {
      for (const key in this.headerMenuHTMLAttributes) {
        if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeaderMenu.nativeElement.attributes[key] = this.headerMenuHTMLAttributes[key];
        }
      }
    }

    KTUtil.ready(() => {

      // Init Desktop & Mobile Headers
      KTLayoutHeader.init('kt_header', 'kt_header_mobile');
      
    });

  }

  ngOnDestroy() {
    
    if (this.routerLoaderTimout) clearTimeout(this.routerLoaderTimout);

    this.destroy$.next();
    this.destroy$.complete();

  }

  public setLogo(url: string) {

    this.logoPath = url || `https://eccocpeu.blob.core.windows.net/logo/el-logo.png`;
    this.logoPathDark = url || `https://eccocpeu.blob.core.windows.net/logo/el-logo.png`;

  }

  headerMenuOpen(menu: string) {
    return this.whichHeaderMenuIsOpen = menu;
  }

}
