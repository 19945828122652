import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Asset} from "./asset.model";

@Injectable({providedIn: 'root'})
export class AssetService extends EntityCollectionServiceBase<Asset> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('assets', serviceElementsFactory);
    }
}
