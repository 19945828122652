import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {SplashScreenService} from './splash-screen.service';
import {environment} from '../../../../../environments/environment';
import {UiService} from '../../../../lib/ui/services/ui.service';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
    @ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;

    logoPath: string;

    constructor(
        private el: ElementRef,
        private splashScreenService: SplashScreenService,
        private ui: UiService
    ) {
        //this.logoPath = `https://ile-cp.s3.eu-central-1.amazonaws.com/cp/logo/${environment.prefix}-logo.png`;
        //this.logoPath = `https://eccocpeu.blob.core.windows.net/logo/${environment.prefix}-logo.png`;
    }

    ngOnInit(): void {
        this.splashScreenService.init(this.splashScreen);
    }
}
