import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import { CmsWebsite } from 'src/app/data/cms-website/cms-website.model';

import {environment as env } from 'src/environments/environment';

const LAYOUT_CONFIG_LOCAL_STORAGE_KEY = `${env.appVersion}-layoutConfig`;
const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';
const ASIDE_DYNAMIC_EXPAND_STATUS_KEY = 'aside-dynamic-expand-status';

@Injectable({
    providedIn: 'root',
})
export class ShareService {
    constructor(private storage: StorageService) {}

    // layout
    getLayoutConfig() {
        return this.storage.get(LAYOUT_CONFIG_LOCAL_STORAGE_KEY);
    }
    setLayoutConfig(config) {
        return this.storage.set(LAYOUT_CONFIG_LOCAL_STORAGE_KEY, config);
    }
    removeLayoutConfig() {
        return this.storage.remove(LAYOUT_CONFIG_LOCAL_STORAGE_KEY);
    }

    // admin: dynamic aside menu
    setAdminMenuExpandStatus(status) {
        this.storage.set(ASIDE_DYNAMIC_EXPAND_STATUS_KEY, status);
    }
    getAdminMenuExpandStatus() {
        return this.storage.get(ASIDE_DYNAMIC_EXPAND_STATUS_KEY) || {};
    }

    // language
    setLocalization(lang) {
        this.storage.set(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
    }
    getLocalization() {
        return this.storage.get(LOCALIZATION_LOCAL_STORAGE_KEY);
    }

    // cms
    // setCmsInvitation(code) {
    //     this.storage.set('cms_invitation', code);
    // }
    // getCmsInvitation() {
    //     return this.storage.get('cms_invitation');
    // }

    // collection
    // setCollectionFilter(collectionId, filter) {
    //     try {
    //         let collection_filter = this.storage.get(`collection_filter`) || {};
    //         collection_filter[`collection${collectionId}`] = filter;
    //
    //         this.storage.set(`collection_filter`, collection_filter);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    //
    // getCollectionFilter(collectionId) {
    //     try {
    //         var collection_filter = this.storage.get(`collection_filter`);
    //         if (!collection_filter) return null;
    //         var filter = collection_filter[`collection${collectionId}`];
    //
    //         return filter;
    //     } catch (e) {
    //         console.log(e);
    //     }
    //     return null;
    // }
    //
    // setCollectionGroup(collectionId, group) {
    //     try {
    //         var collection_group = this.storage.get(`collection_group`) || {};
    //         collection_group[`collection${collectionId}`] = group;
    //
    //         this.storage.set(`collection_group`, collection_group);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    //
    // getCollectionGroup(collectionId) {
    //     try {
    //         var collection_group = this.storage.get(`collection_group`);
    //         if (!collection_group) return null;
    //         var group = collection_group[`collection${collectionId}`];
    //
    //         return group;
    //     } catch (e) {
    //         console.log(e);
    //     }
    //     return null;
    // }
    //
    // setCollectionOrder(collectionId, order) {
    //     try {
    //         var collection_order = this.storage.get(`collection_order`) || {};
    //         collection_order[`collection${collectionId}`] = order;
    //
    //         this.storage.set(`collection_order`, collection_order);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    //
    // getCollectionOrder(collectionId) {
    //     try {
    //         var collection_order = this.storage.get(`collection_order`) || {};
    //         if (!collection_order) return null;
    //         var order = collection_order[`collection${collectionId}`];
    //
    //         return order;
    //     } catch (e) {
    //         console.log(e);
    //     }
    //     return null;
    // }
}
