import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSvgDirective } from 'src/app/shared/directives';
import { RouterModule } from '@angular/router';

export const MODULES = [
  CommonModule,
  RouterModule,
]
export const PIPES = [
  // SafePipe,
]
export const DIRECTIVES = [
  InlineSvgDirective,
]

@NgModule({
  declarations: [PIPES, DIRECTIVES],
  imports: [MODULES],
  exports: [PIPES, DIRECTIVES, MODULES],
})
export class SharedModule { }
