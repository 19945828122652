import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {UiEntity} from "./ui-entity.model";

@Injectable({providedIn: 'root'})
export class UiEntityService extends EntityCollectionServiceBase<UiEntity> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ui-entitys', serviceElementsFactory);
    }
}
