import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Nft} from "./nft.model";

@Injectable({providedIn: 'root'})
export class NftService extends EntityCollectionServiceBase<Nft> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('nfts', serviceElementsFactory);
    }
}
