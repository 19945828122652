import {environment} from 'src/environments/environment';

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

const emptyMenuConfig = {
    items: []
};

@Injectable({
    providedIn: 'root'
})
export class DynamicHeaderMenuService {
    private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
    menuConfig$: Observable<any>;

    constructor() {
        this.menuConfig$ = this.menuConfigSubject.asObservable();
        //this.loadMenu();
    }

    // Here you able to load your menu from server/data-base/localeStorage
    // Default => from DynamicHeaderMenuConfig
    // private loadMenu() {
    //     //console.log(this.authService.userHasRole(['admin']));
    //     if (this.authService.userHasRole(['admin'])) {
    //         this.setMenu(DynamicHeaderMenuConfig);
    //     }
    //     else {
    //         this.setMenu(DynamicHeaderMenuViewerConfig);
    //     }
    // }

    public setMenu(menuConfig) {
        this.menuConfigSubject.next(menuConfig);
    }

    private getMenu(): any {
        return this.menuConfigSubject.value;
    }
}
