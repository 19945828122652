import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {ElNftHomeComponent} from "./el-nft-home/el-nft-home.component";
import {AuthGuard} from "../auth/_services/auth.guard";

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: 'home',
        component: ElNftHomeComponent,
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ElNftRoutingModule {
}
