import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {AuthModel} from '../../_models/auth.model';
import {User} from '../../../../data/user/user.model';
import { Document } from 'src/app/data/document/document.model';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    constructor(private http: HttpClient) {
    }

    // public methods
    login(email: string, password: string): Observable<any> {
        return this.http.post<AuthModel>('/api/auth/login', {username: email.toLowerCase(), password: password});
    }

    loginViaInvitation(code: string): Observable<any> {
        return this.http.post<AuthModel>('/api/auth/invitation', {code: code});
    }

    loginSso(token: string): Observable<any> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        return this.http.post<AuthModel>('/api/auth/login-sso', {}, {
            headers: httpHeaders,
        });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        return this.http.post<User>(API_USERS_URL, user);
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        return this.http.post<boolean>(`/api/auth/forgot-password`, {
            email,
        });
    }

    resetPassword(token: string, password: string): Observable<boolean> {
        return this.http.post<boolean>(`/api/auth/reset-password`, {
            token,
            password
        });
    }

    getUserByToken(token): Observable<User> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });

        return this.http.get<User>('/api/me', {
            headers: httpHeaders,
        });
    }

    getTermsAndServiceContent(): Observable<Document> {
        return this.http.get<Document>('/api/auth/terms-and-services');
    }
}
