import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {UiProperty} from "./ui-property.model";

@Injectable({providedIn: 'root'})
export class UiPropertyService extends EntityCollectionServiceBase<UiProperty> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ui-propertys', serviceElementsFactory);
    }
}
