import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { query,  } from './shared/helpers';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {

  ngAfterViewInit(): void {
    
    // hide ng version
    query('[ng-version]')?.removeAttribute('ng-version');

  }

  ngOnInit(): void {
    this
  }

}
