import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FirstLetterPipe} from './pipes/first-letter.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {TimeElapsedPipe} from './pipes/time-elapsed.pipe';
import {IndeterminateDirective} from './directives/indeterminate/indeterminate.directive';

@NgModule({
    declarations: [
        FirstLetterPipe,
        SafePipe,
        FilterPipe,
        TimeElapsedPipe,
        IndeterminateDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FirstLetterPipe,
        SafePipe,
        FilterPipe,
        TimeElapsedPipe,
        IndeterminateDirective
    ],
})
export class CoreModule {
}
