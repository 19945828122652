import {Injectable, NgModule} from '@angular/core';
import {Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LayoutComponent} from './_layout/layout.component';
// import {RoleGuard} from '../modules/auth/_services/role.guard';
// import {AuthGuard} from '../modules/auth/_services/auth.guard';
import {environment} from '../../environments/environment';
import {ElNftModule} from "../modules/el-nft-module/el-nft.module";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => ElNftModule,
      },
      {
        path: '**',
        redirectTo: 'errors/404',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
