import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Subscription, Observable} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {Router} from '@angular/router';
import {ConfirmPasswordValidator} from './confirm-password.validator';
import {first} from 'rxjs/operators';
import {User} from '../../../data/user/user.model';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
    registrationForm: FormGroup;
    hasError: boolean;
    isLoading$: Observable<boolean>;

    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router
    ) {
        this.isLoading$ = this.authService.isLoading$;
        // redirect to home if already logged in
        if (this.authService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        this.initForm();
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.registrationForm.controls;
    }

    initForm() {
        this.registrationForm = this.fb.group(
            {
                fullname: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(100),
                    ]),
                ],
                email: [
                    'qwe@qwe.qwe',
                    Validators.compose([
                        Validators.required,
                        Validators.email,
                        Validators.minLength(3),
                        Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                    ]),
                ],
                password: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(100),
                    ]),
                ],
                cPassword: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(100),
                    ]),
                ],
                agree: [false, Validators.compose([Validators.required])],
            },
            {
                validator: ConfirmPasswordValidator.MatchPassword,
            }
        );
    }

    submit() {
        this.hasError = false;
        const result = {};
        Object.keys(this.f).forEach(key => {
            result[key] = this.f[key].value;
        });
        const newUser = new User();
        // TODO newUser.setUser(result);
        const registrationSubscr = this.authService
            .registration(newUser)
            .pipe(first())
            .subscribe((user: User) => {
                if (user) {
                    this.router.navigate(['/']);
                } else {
                    this.hasError = true;
                }
            });
        this.unsubscribe.push(registrationSubscr);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
