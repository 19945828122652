<div class="row el-block el-block-md-fixed no-gutters el-dark">
    <div class="col-md-6 keep-aspect aspect-1x1">
        <img [src]="user.nftUrl" class="img-fluid"/>
    </div>

    <div class="col-md-6 d-flex flex-column justify-content-between p-4 keep-aspect aspect-1x1">
        <div class="mb-4 text-uppercase">
            <h1 class="el-font-xl el-text-muted">{{ user.companyName }}</h1>
            <h1 class="el-font-xl">{{ user.name }} {{ user.surname }}</h1>
            <h1 class="el-font-xl el-text-muted mt-5 mb-5">{{ user.workingGroup }}</h1>

            <div class="perks" *ngIf="user.perks.length">
                <h4 class="mb-3">PERKS</h4>
                <div class="perk mb-3 pl-5" *ngFor="let perk of user.perks">
                    <h4>{{ perk.name }}</h4>
                    <p>{{ perk.description }}</p>
                </div>
            </div>

            <p *ngIf="!user.perks.length">
                Welcome to the Inaugural HOT-SHOP 15_NFT_Experience.
                <br/>
                <br/>
                In correlation with this year’s HOT-SHOP, we have gifted you our first ever_Tannery NFT_.<br/>
                This NFT is your pass to multiple experiences during your stay and beyond, from your team group to special 1-of-1 perks.
                <br/>
                <br/>
                The possibilities are endless!
            </p>

        </div>
    </div>
</div>
