import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {NftTransferRequest} from "./nft-transfer-request.model";

@Injectable({providedIn: 'root'})
export class NftTransferRequestService extends EntityCollectionServiceBase<NftTransferRequest> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('nft-transfer-requests', serviceElementsFactory);
    }
}
