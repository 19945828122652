import {Injectable, TemplateRef} from '@angular/core';
// import {LogService} from '../../../data/log/log.service';
// import {AuthService} from '../../../modules/auth/_services/auth.service';
// import {Log} from '../../../data/log/log.model';
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
// import {NotificationService, Position} from '@progress/kendo-angular-notification';
// import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
// import {environment} from '../../../../environments/environment';
// import {DynamicAsideMenuService, DynamicHeaderMenuService} from '../../../_metronic/core';
// import {CollectionGroups} from '../../../data/collection-group/collection-groups.enum';
// import {DataExService} from '../../../data/data-ex.service';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';

// import {ShareService} from 'src/app/services/share.service';
// import {UiMenuItem} from '../../../data/ui-menu-item/ui-menu-item.model';
// import {orderBy} from '@progress/kendo-data-query';
// import {UiMenu} from '../../../data/ui-menu/ui-menu.model';
import {LocalStorageService} from '../../../_metronic/core/services/local-storage.service';
import {CmsWebsite} from "../../../data/cms-website/cms-website.model";
import {CmsPage} from '../../../data/cms-page/cms-page.model';
// import {FormBuilder, Validators} from '@angular/forms';
// import {FormDialogComponent} from '../modals/form-dialog/form-dialog.component';
import { CmsMenuItem } from 'src/app/data/cms-menu-item/cms-menu-item.model';
import { CmsMenu } from 'src/app/data/cms-menu/cms-menu.model';

@Injectable({
    providedIn: 'root'
})
export class UiService {

  public cmsMenu: any = {};
  public menus: any = {};
  public settings: any = {};
  public settingsMap: any = {};
  
  public isOverlayVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);

  public website$: ReplaySubject<CmsWebsite> = new ReplaySubject();

  public invitation$: ReplaySubject<string> = new ReplaySubject();

  // public menu$: ReplaySubject<CmsMenu|null> = new ReplaySubject();

  public homeUrl$: ReplaySubject<string> = new ReplaySubject();

  public isWebsiteLoaded$: ReplaySubject<boolean> = new ReplaySubject();

  public currentCmsPageSubject: ReplaySubject<CmsPage> = new ReplaySubject();
  public currentCmsPage$: Observable<CmsPage> = this.currentCmsPageSubject.asObservable();

  private globalBackgroundSubject: ReplaySubject<string> = new ReplaySubject();
  public globalBackground$ = this.globalBackgroundSubject.asObservable();

  private logoSubject: ReplaySubject<string> = new ReplaySubject();
  public logo$: Observable<any> = this.logoSubject.asObservable();

  public pageSwitchedAt$: ReplaySubject<number> = new ReplaySubject();

  private headerContainerCSSClasses: ReplaySubject<string> = new ReplaySubject();
  public headerContainerCSSClasses$: Observable<any> = this.headerContainerCSSClasses.asObservable();

    constructor(
      // private logService: LogService,
      // private modalService: NgbModal,
      // private notificationService: NotificationService,
      // private dialogService: DialogService,
      // private headerMenuService: DynamicHeaderMenuService,
      // private asideMenuService: DynamicAsideMenuService,
      // private authService: AuthService,
      // private formBuilder: FormBuilder,
      private localStorage: LocalStorageService,
    ) {}

  // initCore(settings) {
  //     this.settings = {};
  //     this.settingsMap = {};

  //     settings.forEach(item => {
  //         this.settings[item.code] = item;
  //         this.settingsMap[item.code] = item.value;
  //     });

  //     this.updateLogo(this.getSetting('logo'));

  //     this.isDebug = this.getSettingBool(`ui.isDebug`, true);
  //     this.showAdvancedProperties = this.getSettingBool(`ui.showAdvancedProperties`, true);
  // }

  // Menu

  // initMenus(menus) {
  //     if (!menus || !menus.length) {
  //         return;
  //     }

  //     this.menus = {};

  //     for (let menu of menus) {
  //         this.menus[menu.code] = this.prepareMenu(menu);
  //     }

  //     this.headerMenuService.setMenu(this.menus['header']);
  // }

  // isMenuItemAvailableForRole(item: any) {
  //     // item.roles.length == 0 means if no role set, then item can see anyone
  //     return !item.roles || item.roles.length == 0 || this.authService.userHasRole(item.roles.map(role => role.code));
  // }

  public prepareMenu(menu: CmsMenu) {

    let items: CmsMenuItem[] = menu.items.sort((a, b)=> a.position! - b.position!)

    // Filter for role
    items = items.filter((item) => item.isActive);

    // Remove all subitems that are in non-existing categoires (e.g. filtered out for role)
    let itemsIds = items.map((item: CmsMenuItem) => item.id);
    items = items.filter((item: CmsMenuItem) => !item.categoryId || itemsIds.indexOf(item.categoryId) !== -1);

    // Add `items` to categories
    let subitems = items.filter(item => item.categoryId);
    items = items
      .filter(item => !item.categoryId || item.isCategory)
      .map(item => item.isCategory ? {
        ...item,
        items: subitems.filter(subitem => subitem.categoryId == item.id),
        submenu: subitems.filter(subitem => subitem.categoryId == item.id),
      } : item);

    return { 
      ...menu, 
      items, 
      submenu: items 
    };
  }
 
  public switchPageId$: Subject<number> = new Subject();

    // CMS

  // setInvitation(code) {
  //     this.invitation = code;
  //     this.localStorageService.set('cms.invitationCode', code);
  // }

  // updateLogo(url) {
  //     this.logoSubject.next(url);
  // }

  // updateHeaderContainerCss(css: string) {
  //     this.headerContainerCSSClasses.next(css);
  // }

  // setGlobalBackground(url: string) {
  //     this.globalBackgroundSubject.next(url);
  // }

  // setWebsiteLoaded(state: boolean) {
  //     this.websiteLoadedSubject.next(state);
  // }

  // setCurrentCmsPage(page: CmsPage) {
  //     this.currentCmsPageSubject.next(page);
  // }

  // // System

  // getEnvPrefix() {
  //     return `${environment.prefix}-`; // not used?
  // }

  // logAction(text: string) {
  //     this.logService.add({text: text, user: this.authService.currentUser} as Log);
  // }

    // Settings

  public getSetting(code: string, isLocal: boolean = false): any {
    if (isLocal) {
      return this.localStorage.get(code);
    } else {
      return this.settingsMap[code];
    }
  }

  // getSettingBool(code: string, isLocal: boolean = false) {
  //     let value = this.getSetting(code, isLocal);

  //     if (typeof value == 'string') {
  //         value = value?.toLowerCase();
  //         return value == '1' || value == 'true' || value == 'yes' || value == 'on' || value == 'enabled' || value == 't';
  //     }

  //     return !!value;
  // }

  // getSettingNumber(code: string, isLocal: boolean = false) {
  //     let value = this.getSetting(code, isLocal);

  //     return +value;
  // }

  // getSettingArray(code: string, isLocal: boolean = false) {
  //     let value = this.getSetting(code, isLocal);
  //     const valueArray = value ? value.split(',') : [];

  //     return valueArray;
  // }


  // setSetting(code: string, value: any, isLocal: boolean = true) {
  //     if (isLocal) {
  //         this.localStorageService.set(code, value);
  //     } else {
  //         // ?
  //     }
  // }

  // removeSetting(code: string, isLocal: boolean = true) {
  //     if (isLocal) {
  //         this.localStorageService.remove(code);
  //     }
  // }

  // // TODO getSettingsInt, getSettingsString, getSettingsJSON, getSettingsArray

  // isServiceEnabledInSettings(serviceCode: string): boolean {
  //     return this.getSettingBool(`service.${serviceCode}.enabled`);
  // }

  // // Dialogs

  // openModalDialog(content: any, options?: NgbModalOptions) {
  //     return this.modalService.open(content, options ?? {size: 'xl', centered: true});
  // }

  // showNotification(
  //     content: string | TemplateRef<any> | Function,
  //     type: 'none' | 'success' | 'warning' | 'error' | 'info' = 'success',
  //     position: Position = {horizontal: 'center', vertical: 'bottom'},
  //     _userHasToClose = null,
  // ) {
  //     let userHasToClose = type == 'error';
  //     if (_userHasToClose != null) {
  //         userHasToClose = _userHasToClose;
  //     }

  //     this.notificationService.show({
  //         content: content,
  //         hideAfter: userHasToClose ? null : 2000,
  //         animation: {type: 'slide', duration: 150},
  //         position: position,
  //         type: {style: type, icon: false},
  //         closable: userHasToClose,
  //     });
  // }

  // showMessage(content, title = ''): Promise<boolean> {
  //     return new Promise<boolean>((resolve, reject) => {
  //         const dialog: DialogRef = this.dialogService.open({
  //             title: title,
  //             content: content,
  //             actions: [{text: 'OK'}],
  //             minWidth: 300,
  //             minHeight: 200,
  //         });

  //         dialog.result.subscribe(resolve);
  //     });
  // }

  // async askString(initial) {
  //     let form = this.formBuilder.group({
  //         email: [
  //             initial,

  //             Validators.compose([
  //                 Validators.required,
  //             ]),
  //         ],
  //     });

  //     const dlg = this.openModalDialog(FormDialogComponent, {size: 'sm', centered: true});
  //     dlg.componentInstance.fieldTitle = 'Material number';
  //     dlg.componentInstance.title = 'Material number';
  //     dlg.componentInstance.form = form;

  //     const res = await dlg.result;

  //     return form.controls.email.value;

  //     // return dlg.result;
  //         // .then(async (r: any) => {
  //         //     const email = form.controls.ss.value;
  //         //     const res = await this.userService.sendInvite(customer.userId, email);
  //         //     this.ui.showNotification('Invite has been sent.');
  //         // })
  //         // .catch((e) => {
  //         //     this.ui.showNotification('Error sending an invite.', 'error');
  //         // });
  // }

  // askConfirmation(content: any, title = 'Confirmation'): Promise<boolean> {
  //     return new Promise<boolean>((resolve, reject) => {
  //         const dialog: DialogRef = this.dialogService.open({
  //             title: title,
  //             content: content,
  //             actions: [{text: 'No'}, {text: 'Yes', primary: true}],
  //             minWidth: 300,
  //             minHeight: 200,
  //         });

  //         dialog.result.subscribe((result) => {
  //             if (result instanceof DialogCloseResult) {
  //                 resolve(false);
  //             } else {
  //                 resolve(result.text === 'Yes');
  //             }
  //         });
  //     });
  // }

  // // OS/Browser checks
  // checkBrowser() {
  //     if (navigator.userAgent.indexOf('Chrome') != -1 && navigator.userAgent.indexOf('Firefox') != -1) {
  //         this.showNotification('We recommend to use Google Chrome or Firefox', 'warning', {
  //             horizontal: 'center',
  //             vertical: 'top'
  //         }, true);
  //     }
  // }

  // Preview (3D/Lightbox)

  // TODO showProductPreview
}
