import {Perk} from "../perk/perk.model";
import {Nft} from "../nft/nft.model";
import {NftTransferRequest} from "../nft-transfer-request/nft-transfer-request.model";
import {Acl} from "../acl/acl.model";
import {Role} from "../role/role.model";
import { Token } from '../token/token.model';


export class User {
        id?: number;

        isActive: boolean;

            name?: string;

        workingGroup?: string;

        nftUrl?: string;

        accessToken?: string;

            surname?: string;

            email?: string;

        password: string;

        needPasswordReset: boolean;

        jobTitle?: string;

        companyName?: string;

        avatar?: string;

        isSystem?: boolean;

        createdAt?: Date;

        updatedAt?: Date;

            roles: Role[];


        acls: Acl[];

        tokens: Token[];

        nftTransferRequests: NftTransferRequest[];

        nfts: Nft[];

    // TODO qfix
            perks: Perk[];
}
