import {NgModule} from '@angular/core';
// import {FullCalendarModule} from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
// import interactionPlugin from '@fullcalendar/interaction';
// import {CommonModule} from '@angular/common';
// import {Scene3dBaseComponent} from './3d/scene3d-base/scene3d-base.component';
// import {ScheduleWidgetComponent} from './widgets/home/schedule/schedule-widget.component';
// import {NewsWidgetComponent} from './widgets/home/news/news-widget.component';
// import {BannerWidgetComponent} from './widgets/home/banner/banner-widget.component';
// import {LiveWidgetComponent} from './widgets/home/live/live-widget.component';
// import {BlogWidgetComponent} from './widgets/home/blog/blog-widget.component';
// import {VideosWidgetComponent} from './widgets/home/videos/videos-widget.component';
import {CoreModule} from '../../_metronic/core';

// import {NgbCarouselModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
// import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {DialogsModule} from '@progress/kendo-angular-dialog';
// import {RouterModule} from '@angular/router';
// import {LiveListWidgetComponent} from './widgets/home/live/live-list-widget/live-list-widget.component';
import {DataModule} from '../../data/data.module';
// import {DropDownListFilterComponent} from './kendo/drop-down-list-filter/drop-down-list-filter.component';
// import {ModalProductSearchComponent} from './modal-product-search/modal-product-search.component';
// import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
// import {ChoiceDialogComponent} from './modals/choice-dialog/choice-dialog.component';
// import {MultiChoiceDialogComponent} from './modals/muti-choice-dialog/multi-choice-dialog.component';
// import {BugReportComponent} from './widgets/bug-report/bug-report.component';
// import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// import {ListViewModule} from '@progress/kendo-angular-listview';
// import {NotificationModule} from '@progress/kendo-angular-notification';
// import {TreeViewModule} from '@progress/kendo-angular-treeview';
// import { ImageZoomComponent } from './controls/image-zoom/image-zoom.component';
// import { ObjectViewer3dComponent } from './3d/object-viewer3d/object-viewer3d.component';
// import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
// import { MediaPlayerComponent } from './controls/media-player/media-player.component';
// import { TabStripModule } from '@progress/kendo-angular-layout';
// import { CopyClipboardComponent } from './controls/copy-clipboard/copy-clipboard.component';
// import { ClipboardModule } from 'ngx-clipboard';
// import { SearchBarComponent } from './controls/search-bar/search-bar.component';
// import { PlaylistComponent } from './controls/playlist/playlist.component';
// import { AssetPreviewComponent } from './controls/asset-preview/asset-preview.component';
// import { InlineSVGModule } from 'ng-inline-svg';
// import { FormDialogComponent } from './modals/form-dialog/form-dialog.component';
// import {AvatarComponent} from './controls/avatar/avatar.component';
// import {SlideShowComponent} from './controls/slide-show/slide-show.component';
// import {ColorPickerComponent} from './controls/color-picker/color-picker.component';
// import { ColorListComponent } from './controls/color-picker/color-list/color-list.component';
// import {ProductViewer3d} from './3d/product-viewer3d/product-viewer3d.component';
// import {ColorPickerModule} from '@progress/kendo-angular-inputs';
// import {AnnotationDialogComponent} from './3d/modals/annotation-dialog/annotation-dialog.component';
// import {RotatiePositionManageDialogComponent} from './3d/modals/rotate-position-manage-dialog/rotate-position-manage-dialog.component';
// import {RotatiePositionNameDialogComponent} from './3d/modals/rotate-position-name-dialog/rotate-position-name-dialog.component';
// import {KeepAspectRatioDirective} from './directives/keep-aspect-ratio.directive';
import { SharedModule } from 'src/app/shared/modules';

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//     dayGridPlugin,
//     timeGridPlugin,
//     listPlugin,
//     interactionPlugin
// ]);

@NgModule({
  declarations: [
    // Scene3dBaseComponent,
    // ScheduleWidgetComponent,
    // NewsWidgetComponent,
    // BannerWidgetComponent,
    // LiveWidgetComponent,
    // BlogWidgetComponent,
    // VideosWidgetComponent,
    // LiveListWidgetComponent,
    // DropDownListFilterComponent,
    // ChoiceDialogComponent,
    // MultiChoiceDialogComponent,
    // BugReportComponent,
    // ModalProductSearchComponent,
    // ImageZoomComponent,
    // ObjectViewer3dComponent,
    // MediaPlayerComponent,
    // CopyClipboardComponent,
    // SearchBarComponent,
    // AssetPreviewComponent,
    // PlaylistComponent,
    // FormDialogComponent,
    // AvatarComponent,
    // SlideShowComponent,
    // ColorPickerComponent,
    // ColorListComponent,
    // ProductViewer3d,
    // KeepAspectRatioDirective,
    // AnnotationDialogComponent,
    // RotatiePositionManageDialogComponent,
    // RotatiePositionNameDialogComponent,
  ],
  imports: [
    // CommonModule,
    // FullCalendarModule,
    CoreModule,
    // NgbDropdownModule,
    // FormsModule,
    // DialogsModule,
    // RouterModule,
    SharedModule,
    DataModule,
    // DropDownsModule,
    // PerfectScrollbarModule,
    // ListViewModule,
    // NotificationModule,
    // TreeViewModule,
    // FontAwesomeModule,
    // ClipboardModule,
    // ReactiveFormsModule,
    // InlineSVGModule,
    // NgbCarouselModule,
    // ColorPickerModule,
  ],
  exports: [
    // ScheduleWidgetComponent,
    // NewsWidgetComponent,
    // BannerWidgetComponent,
    // LiveWidgetComponent,
    // BlogWidgetComponent,
    // VideosWidgetComponent,
    // LiveListWidgetComponent,
    // BugReportComponent,
    // ModalProductSearchComponent,
    // ObjectViewer3dComponent,
    // MediaPlayerComponent,
    // CopyClipboardComponent,
    // SearchBarComponent,
    // PlaylistComponent,
    // AssetPreviewComponent,
    // AvatarComponent,
    // SlideShowComponent,
    // ColorPickerComponent,
    // ColorListComponent,
    // ProductViewer3d,
    // KeepAspectRatioDirective,
  ]
})
export class UiModule {}
