import {NgModule} from '@angular/core';
import {LayoutComponent} from './_layout/layout.component';
import {ScriptsInitComponent} from './_layout/init/scipts-init/scripts-init.component';
import {HeaderMobileComponent} from './_layout/components/header-mobile/header-mobile.component';
import {FooterComponent} from './_layout/components/footer/footer.component';
import {HeaderComponent} from './_layout/components/header/header.component';
import {HeaderMenuComponent} from './_layout/components/header/header-menu/header-menu.component';
import {CoreModule} from '../_metronic/core';
import {HeaderMenuDynamicComponent} from './_layout/components/header/header-menu-dynamic/header-menu-dynamic.component';
import {LayoutRoutingModule} from './layout-routing.module';
import {UiModule} from '../lib/ui/ui.module';
import { SharedModule } from '../shared/modules';

@NgModule({
  declarations: [
    LayoutComponent,
    ScriptsInitComponent,
    HeaderMobileComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    HeaderMenuDynamicComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    LayoutRoutingModule,
    UiModule,
  ],
  exports: [
    LayoutComponent,
  ]
})
export class LayoutModule {}
