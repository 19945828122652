import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter
 */
@Pipe({name: 'filterPipe'})

export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[]{
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter(x => {
      return x.name.toLowerCase().indexOf(searchText) !== -1
    });
  }
}