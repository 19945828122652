import { Component, OnInit, AfterViewInit, Input, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LayoutService } from '../../../../_metronic/core';
import {environment} from "../../../../../environments/environment";
import {UiService} from '../../../../lib/ui/services/ui.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, } from 'rxjs/operators';
import {KTUtil} from '../../../../../assets/js/components/util';
import KTLayoutHeaderMenu from '../../../../../assets/js/layout/base/header-menu';
import KTLayoutHeaderTopbar from '../../../../../assets/js/layout/base/header-topbar';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileComponent implements OnInit, AfterViewInit, OnDestroy {

  public headerLogo = '';
  public headerLogoDark = '';
  
  public asideSelfDisplay = true;
  public headerMenuSelfDisplay = true;
  public headerMobileClasses = '';
  public headerMobileAttributes = {};

  private destroy$: Subject<void>

  constructor(
    private layout: LayoutService,
    public ui: UiService) {

      this.destroy$ = new Subject();

      this.ui.logo$.pipe(
        takeUntil(this.destroy$)).subscribe(logo=>{
          if(logo) {
            this.headerLogo = logo;
          } else {
            this.headerLogo = this.getLogoUrl();
          }
        })
  }

  ngOnInit(): void {

    // build view by layout config settings
    this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
    this.headerMobileAttributes = this.layout.getHTMLAttributes('header_mobile');

    this.headerLogo = this.getLogoUrl();
    this.asideSelfDisplay = this.layout.getProp('aside.self.display');
    this.headerMenuSelfDisplay = this.layout.getProp('header.menu.self.display');

    this.headerLogo = `https://eccoleather.blob.core.windows.net/static/logo/ecco-leather-logo-black.png`;
    this.headerLogoDark = `https://eccoleather.blob.core.windows.net/static/logo/ecco-leather-logo-black.png`;

  }

  ngAfterViewInit() {
    
    KTUtil.ready(() => {
      // Init Header Menu
      KTLayoutHeaderMenu.init('kt_header_menu', 'kt_header_menu_wrapper');
    });

  }

  ngOnDestroy() {

    this.destroy$.next();
    this.destroy$.complete();
    
  }

  private getLogoUrl(): string {

    let result = 'logo-light.png';
    const headerSelfTheme = this.layout.getProp('header.self.theme') || '';
    const brandSelfTheme = this.layout.getProp('brand.self.theme') || '';

    if (!this.asideSelfDisplay) {
      if (headerSelfTheme === 'light') {
        result = 'logo-dark.png';
      }
    } else {
      if (brandSelfTheme === 'light') {
        result = 'logo-dark.png';
      }
    }

    return 'https://eccocpeu.blob.core.windows.net/logo/el-logo.png';
    
  }
}
