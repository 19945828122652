

<!--begin::Toolbar-->
<div class="d-flex align-items-center header-mobile-nav--container">
  <ng-container *ngIf="asideSelfDisplay">
    <!--begin::Aside Mobile Toggle-->
    <button
      id="kt_aside_mobile_toggle"
      class="btn p-0 burger-icon burger-icon-left">
      <span></span>
    </button>
  </ng-container>

  <ng-container *ngIf="headerMenuSelfDisplay">
    <!--begin::Header Menu Mobile Toggle-->
    <button
      class="btn header-mobile-nav--main"
      id="kt_header_mobile_toggle">
      <img src="/assets/media/icons/icon--menu.svg" alt="Toggle Main Menu">
    </button>
    <!--end::Header Menu Mobile Toggle-->
  </ng-container>

  <!--begin::Logo-->
  <a [routerLink]="ui.homeUrl$|async" class="header-mobile-nav--logo">
    <img alt="Logo" [attr.src]="headerLogo" class="logo-default max-h-40px p-2" />
  </a>
  <!--end::Logo-->

  <button
    id="kt_header_mobile_topbar_toggle"
    class="btn header-mobile-nav--account">
    <img src="/assets/media/icons/icon--account.svg" alt="Toggle Account Menu"></button>
</div>
<!--end::Toolbar-->
