// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'el-nft-v1',
  USERDATA_KEY: 'auth2x493fc3a5f55',
  isMockEnabled: false,

  apiUrl: 'api',
  wsUrl: 'ws://localhost:4200/',

  sso: {
    redirectUrl: 'http://localhost:4200/auth/login-redirect',
    redirectUrlAlt: null,
    clientId: '',
    tenantId: '',
  },

  // Extra

  prefix: 'main',

  appName: '',
  useSso: false,
  isReleased: true,

  supportEmail: 'someemail@here',

  messenger: false,
  analytics: false,
  forcePackages: false,
  wishlist: false,

  pages: {
    login: 'login',
    home: 'overview',
    collection: ''
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
