import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {RegistrationComponent} from './registration/registration.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LogoutComponent} from './logout/logout.component';

import {environment} from 'src/environments/environment';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {AuthInvitationComponent} from "./auth-invitation/auth-invitation.component";

const components = {
    'login': LoginComponent,
};

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                //component: environment.useSso ? LoginSsoComponent : LoginComponent,
                component: components[environment.pages['login']],
                data: {returnUrl: window.location.pathname}
            },

            // Disabled based on user journey logic
            // {
            //   path: 'registration',
            //   component: RegistrationComponent
            // },

            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },

            {
                path: 'reset-password/:token',
                component: ResetPasswordComponent
            },
            {
                path: 'invitation/:code',
                component: AuthInvitationComponent
            },
            {
                path: 'logout',
                component: LogoutComponent
            },
            {path: '', redirectTo: 'login', pathMatch: 'full'},
            {path: '**', redirectTo: 'login', pathMatch: 'full'},
        ]
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
