import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../../../../../_metronic/core';

@Component({
  selector: 'app-header-menu',
  template: ``,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuComponent implements OnInit {

  public ulCSSClasses: string = '';
  public rootArrowEnabled: boolean = false;
  public location: Location|null = null;
  public headerMenuDesktopToggle: string = '';

  constructor(
    private layout: LayoutService
  ) {}

  ngOnInit(): void {

    this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
    this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
    this.headerMenuDesktopToggle = this.layout.getProp('header.menu.desktop.toggle');

  }
}
