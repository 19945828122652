import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[inlineSVG]'
})
export class InlineSvgDirective {

  @Input() inlineSVG: string = '';

  constructor() { }

}
