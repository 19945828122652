import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {EmailTemplate} from "./email-template.model";

@Injectable({providedIn: 'root'})
export class EmailTemplateService extends EntityCollectionServiceBase<EmailTemplate> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('email-templates', serviceElementsFactory);
    }
}
