import {NgModule, APP_INITIALIZER, Injectable, ElementRef} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth/_services/auth.service';
import {SplashScreenModule} from './_metronic/partials/layout/splash-screen/splash-screen.module';
import {EntityDataModule} from '@ngrx/data';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {entityConfig} from './data/data.config';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from './shared/modules';
import {LayoutModule} from './pages/layout.module';
import {AuthModule} from "./modules/auth/auth.module";

import {environment} from 'src/environments/environment';

import 'hammerjs';
import {Observable} from "rxjs/internal/Observable";

const localStorageAuthTokenKey = `${environment.appVersion}-${environment.USERDATA_KEY}`;

const getAccessToken = () => {
    let authData;

    try {
        authData = JSON.parse(localStorage.getItem(localStorageAuthTokenKey));
    } catch (error) {

    }

    return authData ? 'Bearer ' + authData.access_token : null;
};

function appInitializer(authService: AuthService) {
    return () => {
        return new Promise((resolve) => {
            authService.getUserByToken().subscribe((data) => {
                resolve(data);
            });
        });
    };
}

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log(req.url);

        if (req.url.indexOf('blob.core.windows.net') > 0) {
            return next.handle(req);
        }

        const access_token = getAccessToken();

        const authReq = !!access_token ? req.clone({
            setHeaders: {Authorization: access_token},
        }) : req;

        return next.handle(authReq);
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        HttpClientModule,
        SharedModule,
        LayoutModule,
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot(entityConfig),
        ReactiveFormsModule,
        AuthModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
            deps: [],
        },

    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
