import {EntityMetadataMap} from "@ngrx/data";

const entityMetadata: EntityMetadataMap = {
    AssetGallery: { entityName: 'asset-gallerys' },
    AssetType: { entityName: 'asset-types' },
    Asset: { entityName: 'assets' },
    NftTransferRequest: { entityName: 'nft-transfer-requests' },
    Nft: { entityName: 'nfts' },
    Perk: { entityName: 'perks' },
    EmailTemplate: { entityName: 'email-templates' },
    UiEntity: { entityName: 'ui-entitys' },
    UiMenuItem: { entityName: 'ui-menu-items' },
    UiMenu: { entityName: 'ui-menus' },
    UiProperty: { entityName: 'ui-propertys' },
    UiSetting: { entityName: 'ui-settings' },
    Acl: { entityName: 'acls' },
    Role: { entityName: 'roles' },
    Token: { entityName: 'tokens' },
    UserAction: { entityName: 'user-actions' },
    User: { entityName: 'users' },

};

const pluralNames = {
    'asset-gallerys': 'asset-gallerys',
    'asset-types': 'asset-types',
    'assets': 'assets',
    'nft-transfer-requests': 'nft-transfer-requests',
    'nfts': 'nfts',
    'perks': 'perks',
    'email-templates': 'email-templates',
    'ui-entitys': 'ui-entitys',
    'ui-menu-items': 'ui-menu-items',
    'ui-menus': 'ui-menus',
    'ui-propertys': 'ui-propertys',
    'ui-settings': 'ui-settings',
    'acls': 'acls',
    'roles': 'roles',
    'tokens': 'tokens',
    'user-actions': 'user-actions',
    'users': 'users',

};

export const entityConfig = {
    entityMetadata,
    pluralNames
};
