import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ElNftHomeComponent} from './el-nft-home/el-nft-home.component';
import {ElNftRoutingModule} from "./el-nft-routing.module";


@NgModule({
    declarations: [
        ElNftHomeComponent
    ],
    imports: [
        CommonModule,
        ElNftRoutingModule
    ]
})
export class ElNftModule {
}
