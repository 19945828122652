import {Component, OnInit, AfterViewInit, HostListener} from '@angular/core';
import {LayoutService} from '../../../../_metronic/core';
import KTLayoutFooter from '../../../../../assets/js/layout/base/footer';
import {UiService} from '../../../../lib/ui/services/ui.service';
import { filter, map, Observable } from 'rxjs';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {

  public footerContainerCSSClasses: string = '';
  public currentYear: string = '';
  public footerLayout: string = '';

  public footerContent$: Observable<string> = this.ui.website$.pipe(
    filter(a=> !!a),
    map(a=> a.cmsFooter?.content || ''))

  constructor(
    private layout: LayoutService,
    public ui: UiService,
  ) {

    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear().toString();

  }

  ngOnInit(): void {

    this.footerContainerCSSClasses = this.layout.getStringCSSClasses('footer_container');
      this.footerLayout = this.layout.getProp('footer.layout');

  }

  ngAfterViewInit() {
    // Init Footer
    KTLayoutFooter.init('kt_footer');
  }
}
