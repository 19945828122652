<div class="page-theme-el" #layout [ngClass]="{ 'dark-mode': isDarkMode$|async }">

  <app-header-mobile id="kt_header_mobile" #ktHeaderMobile class="header-mobile" [ngClass]="headerMobileClasses"></app-header-mobile>

  <div id="mainContent" class="d-flex flex-column flex-root vh-100" [ngClass]="{'no-scrollbar': forceNoScrollbar}" kendoWindowContainer>
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper" [style.background-image]="wrapperBgImage">

        <app-header id="kt_header" #ktHeader class="header" [ngClass]="headerCSSClasses"></app-header>

        <div id="kt_content" class="content d-flex flex-column flex-column-fluid" [ngClass]="contentClasses">
          <div class="d-flex flex-column-fluid">
            <div [ngClass]="contentContainerClasses" class="div-content-container-classes">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
        
        <app-footer id="kt_footer" class="footer d-flex flex-lg-column {{ footerCSSClasses }}" [ngClass]="{ 'hidden': hideFooter$|async }"></app-footer>

        <div class="cms-content-overlay" [ngClass]="{ 'is-open': ui.isOverlayVisible$|async }">
          <div class="cms-content-overlay-inner"></div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-scripts-init></app-scripts-init>
