import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';
import {LayoutService, LayoutInitService} from '../../_metronic/core';
import KTLayoutContent from '../../../assets/js/layout/base/content';
import {Observable, Subject, Subscription} from 'rxjs';
import {UiService} from '../../lib/ui/services/ui.service';
import {filter, map,} from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

// equivalent to template-lv2
// app-layout is actually template LV1 
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  // Public variables
  // public selfLayout: string = 'default';
  // public asideSelfDisplay: boolean = true;
  public contentClasses: string = '';
  public contentContainerClasses: string = 'container-fluid p-0';
  // public subheaderDisplay: boolean = true;
  public contentExtended: boolean = false;
  // public asideCSSClasses: string = '';
  public asideHTMLAttributes: any = {};
  public headerMobileClasses: string = '';

  public headerMobileAttributes: any = {};
  public footerDisplay: boolean|null = null;
  // public footerLayout: string = '';
  public footerCSSClasses: string = '';
  public headerCSSClasses: string = '';
  public headerHTMLAttributes: any = {};
  public extrasScrollTopDisplay = false;

  private destroy$: Subject<void>

  // @ViewChild('ktAside', {static: true}) public ktAside!: ElementRef;
  @ViewChild('ktHeaderMobile', {static: true}) public ktHeaderMobile!: ElementRef;
  @ViewChild('ktHeader', {static: true}) public ktHeader!: ElementRef;
  // @ViewChild('popupChat') public popupChatEle!: ElementRef;

  @ViewChild('layout') public layoutRef!: ElementRef;

  // public isDarkMode: boolean = false;
  public isDarkMode$: Observable<boolean> = this.ui.currentCmsPage$.pipe(
    filter(a=> !!a),
    map(website=> website.isDark));
    
  public forceNoScrollbar: boolean = false;
  public wrapperBgImage: string|null = null;
  
  public subscription = new Subscription();

  public hideFooter$: Observable<boolean> = this.ui.currentCmsPage$.pipe(
    filter(a=> !!a),
    map(a=> a.isHorizontal))

  constructor(
      public ui: UiService,
      private initService: LayoutInitService,
      private layout: LayoutService,
      private cdr: ChangeDetectorRef,
  ) {

    this.destroy$ = new Subject();

    this.initService.init();

  }

  ngOnInit(): void {
    
    this.contentClasses = this.layout.getStringCSSClasses('content');
    this.contentExtended = this.layout.getProp('content.extended');
    this.asideHTMLAttributes = this.layout.getHTMLAttributes('aside');
    this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
    this.headerMobileAttributes = this.layout.getHTMLAttributes('header_mobile');

    // Footer view
    this.footerDisplay = this.layout.getProp('footer.display');
    this.footerCSSClasses = this.layout.getStringCSSClasses('footer');

    // Header view
    this.headerCSSClasses = this.layout.getStringCSSClasses('header');
    this.headerHTMLAttributes = this.layout.getHTMLAttributes('header');
    this.extrasScrollTopDisplay = this.layout.getProp('extras.scrolltop.display');

  }

  ngAfterViewInit(): void {

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[key] = this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] = this.headerHTMLAttributes[key];
        }
      }
    }

    // Init Content
    KTLayoutContent.init('kt_content');

  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
  }
}
