import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {AssetGalleryService} from "./asset-gallery/asset-gallery.service";
import {AssetTypeService} from "./asset-type/asset-type.service";
import {AssetService} from "./asset/asset.service";
import {NftTransferRequestService} from "./nft-transfer-request/nft-transfer-request.service";
import {NftService} from "./nft/nft.service";
import {PerkService} from "./perk/perk.service";
import {EmailTemplateService} from "./email-template/email-template.service";
import {UiEntityService} from "./ui-entity/ui-entity.service";
import {UiMenuItemService} from "./ui-menu-item/ui-menu-item.service";
import {UiMenuService} from "./ui-menu/ui-menu.service";
import {UiPropertyService} from "./ui-property/ui-property.service";
import {UiSettingService} from "./ui-setting/ui-setting.service";
import {AclService} from "./acl/acl.service";
import {RoleService} from "./role/role.service";
import {TokenService} from "./token/token.service";
import {UserActionService} from "./user-action/user-action.service";
import {UserService} from "./user/user.service";



@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        AssetGalleryService,
        AssetTypeService,
        AssetService,
        NftTransferRequestService,
        NftService,
        PerkService,
        EmailTemplateService,
        UiEntityService,
        UiMenuItemService,
        UiMenuService,
        UiPropertyService,
        UiSettingService,
        AclService,
        RoleService,
        TokenService,
        UserActionService,
        UserService,

    ]
})
export class DataModule {
}
