import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {AuthModel} from '../_models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {User} from '../../../data/user/user.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AclAction} from '../../../data/acl/acl.enum';
import {StorageService} from "../../../shared/services/storage.service";

@Injectable({
    providedIn: 'root',
})
@UntilDestroy()
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    public authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

    currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
    currentUser$: Observable<User> = this.currentUserSubject.asObservable();
    currentUser: any; // TODO qfix, refactor

    get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    constructor(private authHttpService: AuthHTTPService,
                private storage: StorageService) {

        // const subscr = this.getUserByToken().subscribe();
        // this.unsubscribe.push(subscr);
    }

    login(email: string, password: string): Observable<any> {
        this.isLoadingSubject.next(true);

        return this.authHttpService.login(email, password).pipe(
            map((auth: AuthModel) => this.setAuthFromLocalStorage(auth)),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    loginViaInvitation(code: string): Observable<any> {
        this.isLoadingSubject.next(true);

        return this.authHttpService.loginViaInvitation(code).pipe(
            map((auth: AuthModel) => this.setAuthFromLocalStorage(auth)),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    logout() {
        this.storage.removeAll();

        // this.router.navigate(['/auth/login'], {
        //     queryParams: {},
        // });
    }

    getUserByToken(): Observable<any> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.access_token) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);

        return this.authHttpService.getUserByToken(auth.access_token)
            .pipe(
                catchError(error => {
                    //console.log('bad stuff:', error)
                    return of(null);
                }),

                map((user: any) => {
                    if (user) {
                        let _user = Object.assign({}, {
                            roles: [],
                            regions: [],
                            myRegions: [],
                        }, user);

                        _user.roles = _user.roles.map(x => x.code);

                        // TODO check if user has role that allow him to access non-released website (outdated)

                        if (!environment.isReleased) {
                            let hasAccess = false;
                            for (let role of _user.roles) {
                                if (['admin', 'top-manager', 'region-manager', 'production', 'early-access', 'event-manager'].indexOf(role) !== -1) {
                                    hasAccess = true;
                                }
                            }

                            if (!hasAccess) {
                                console.log('not realeased yet');
                                return this.logout();
                            }
                        }

                        this.updateUser(_user);
                        // this.currentUser = _user;
                        // this.currentUserSubject.next(_user);
                    } else {
                        this.logout();
                    }

                    return user;
                }),

                finalize(() => this.isLoadingSubject.next(false))
            );
    }

    updateUser(user: User) {
        this.currentUser = user;
        this.currentUserSubject.next(user);
    }

    // need create new user then login
    registration(user: User): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login(user.email, user.password)),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    resetPassword(token: string, password: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .resetPassword(token, password)
            .pipe(map((r: any) => {
                this.isLoadingSubject.next(false)
                if (r.success) return true
                throw Error(r.message)
            }, catchError(e => {
                this.isLoadingSubject.next(false)
                return of(e)
            })));
    }

    setAuthFromLocalStorage(auth: AuthModel): boolean {
        // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        if (auth && auth.access_token) {
            this.storage.set(this.authLocalStorageToken, auth);
            return true;
        }
        return false;
    }

    getAuthFromLocalStorage(): AuthModel {
        try {
            return this.storage.get(this.authLocalStorageToken);
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    // [+]

    userHasRole(roles: string[]) {
        if (!this.currentUser) return false;

        for (let role of roles) {
            if (this.currentUser.roles.indexOf(role) != -1) return true;
        }
    }

    userHasAnyRole() {
        return this.currentUser && this.currentUser.roles.length > 0;
    }

    userIsOwnerOf(item: any) {
        return this.currentUser && item &&
            (item.owner && item.owner.id === this.currentUser.id || item.ownerId === this.currentUser.id);
    }

    isUserAdmin() {
        return this.userHasRole(['admin']);
    }

    // ACL

    objectActionAllowed(objectType: string, objectInstance: any, action: AclAction) {
        return true;
    }
}
