import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {AssetType} from "./asset-type.model";

@Injectable({providedIn: 'root'})
export class AssetTypeService extends EntityCollectionServiceBase<AssetType> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('asset-types', serviceElementsFactory);
    }
}
