import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {UiSetting} from "./ui-setting.model";

@Injectable({providedIn: 'root'})
export class UiSettingService extends EntityCollectionServiceBase<UiSetting> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ui-settings', serviceElementsFactory);
    }
}
