import {Component, OnInit, OnDestroy, ChangeDetectorRef, Input, ChangeDetectionStrategy, ViewEncapsulation} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {LayoutService, DynamicHeaderMenuService} from '../../../../../_metronic/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { trackByRefactored } from 'src/app/shared/helpers';

@Component({
    selector: 'app-header-menu-dynamic',
    templateUrl: './header-menu-dynamic.component.html',
    styleUrls: ['./header-menu-dynamic.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class HeaderMenuDynamicComponent implements OnInit, OnDestroy {

  public currentUrl: string = '';
  public menuConfig: any|null = null;

  public readonly trackById: any = trackByRefactored('id');
  public readonly trackByIndex: any = trackByRefactored();

  @Input() menu: any|null = null;
  @Input() menu$: Observable<any> = of();

  public ulCSSClasses: string = '';
  public rootArrowEnabled: boolean = false;
  public headerMenuDesktopToggle: string = '';

  private destroy$: Subject<void>;

  constructor(
    private layout: LayoutService,
    private router: Router,
    private menuService: DynamicHeaderMenuService,
    private cdr: ChangeDetectorRef
  ) {

    this.destroy$ = new Subject();

  }

  ngOnInit(): void {

    this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
    this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
    this.headerMenuDesktopToggle = this.layout.getProp('header.menu.desktop.toggle');

    try {

      this.currentUrl = this.router.url.split(/[?#]/)[0];

    } catch(e) {}
    
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {

            this.currentUrl = e.url;

            this.cdr.detectChanges();

          });

    if (this.menu) {
      this.menuConfig = this.menu;
    } else {
      // menu load
      this.menuService.menuConfig$
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.menuConfig = res;
          this.cdr.detectChanges();
        });
    }

  }

  public isMenuItemActive(path: string): boolean {

    if (!this.currentUrl || !path) return false;
    if (this.currentUrl === path) return true;
    if (this.currentUrl.indexOf(path) > -1) return true;

    return false;

  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();

  }
}
