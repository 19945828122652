import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Role} from "./role.model";

@Injectable({providedIn: 'root'})
export class RoleService extends EntityCollectionServiceBase<Role> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('roles', serviceElementsFactory);
    }
}
