import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Acl} from "./acl.model";

@Injectable({providedIn: 'root'})
export class AclService extends EntityCollectionServiceBase<Acl> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('acls', serviceElementsFactory);
    }
}
