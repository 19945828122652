import { Component, OnInit } from '@angular/core';
import {UiService} from '../../lib/ui/services/ui.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  today: Date = new Date();
  isFormVisible: boolean = true; // false when not released yet

  constructor(public ui: UiService) { }

  ngOnInit(): void {
    //console.log(this.ui.settingsMap);
  }

}
