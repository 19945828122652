import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {AssetGallery} from "./asset-gallery.model";

@Injectable({providedIn: 'root'})
export class AssetGalleryService extends EntityCollectionServiceBase<AssetGallery> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('asset-gallerys', serviceElementsFactory);
    }
}
