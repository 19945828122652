import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Token} from "./token.model";

@Injectable({providedIn: 'root'})
export class TokenService extends EntityCollectionServiceBase<Token> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('tokens', serviceElementsFactory);
    }
}
