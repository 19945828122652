<ng-container *ngIf="ui.cmsMenu">

  <div class="d-flex justify-content-between">
    <div *ngIf="ui.cmsMenu" class="header-menu-wrapper">
      <app-header-menu-dynamic [menu]="ui.cmsMenu['footer']"
        class="header-menu header-menu-left header-menu-mobile"></app-header-menu-dynamic>
    </div>

    <div>
      <div *ngIf="ui.cmsMenu" class="header-menu-wrapper justify-content-end">
        <app-header-menu-dynamic [menu]="ui.cmsMenu['contact']" class="header-menu"></app-header-menu-dynamic>
      </div>
    </div>
  </div>

  <div [innerHTML]="footerContent$|async|safe:'html'" *ngIf="footerContent$|async"></div>
</ng-container>
