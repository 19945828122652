import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {UiMenuItem} from "./ui-menu-item.model";

@Injectable({providedIn: 'root'})
export class UiMenuItemService extends EntityCollectionServiceBase<UiMenuItem> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ui-menu-items', serviceElementsFactory);
    }
}
