import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Perk} from "./perk.model";

@Injectable({providedIn: 'root'})
export class PerkService extends EntityCollectionServiceBase<Perk> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('perks', serviceElementsFactory);
    }
}
