import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {UiMenu} from "./ui-menu.model";

@Injectable({providedIn: 'root'})
export class UiMenuService extends EntityCollectionServiceBase<UiMenu> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ui-menus', serviceElementsFactory);
    }
}
