import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";

@Component({
    selector: 'app-auth-invitation',
    templateUrl: './auth-invitation.component.html',
    styleUrls: ['./auth-invitation.component.scss']
})
export class AuthInvitationComponent implements OnInit {

    code: string;
    isError: boolean;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.code = this.route.snapshot.params['code'];

        this.authService.loginViaInvitation(this.code).subscribe(res => {
            if (res?.id)
                this.router.navigate(['/']);
            else
                this.isError = true;
        });
    }

}
